
























































































import FilterViewMixin from '@/views/chat/filters/include/FilterViewMixin'
import CommonRulesSettings from '@/views/chat/filters/components/SettingGroups/CommonRulesSettings.vue'
import VirtualNewSettings from '@/views/chat/filters/components/Fields/VirtualNewSettings.vue'
import CommonSettings from '@/views/chat/filters/components/SettingGroups/CommonSettings.vue'
import { CommonRulesFields, ExtraSettingsMenuItems } from '@/views/chat/filters/FilterTypes'
import SectionWrapper from '@/views/chat/filters/components/SectionWrapper.vue'
import FilterLevels from '@/views/chat/filters/components/FilterLevels.vue'
import { VirtualNewFields } from '@/views/chat/filters/FilterTypes'
import chatConfig from '@/mixins/chatConfig'
import { InputSetups } from '@/mixins/input-setups'

import { SelectOption } from 'piramis-base-components/src/logic/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'

import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'

@Component({
  components: {
    SectionWrapper,
    CommonRulesSettings,
    VirtualNewSettings,
    CommonSettings,
    FilterLevels,
  },
  data() {
    return {
      ExtraSettingsMenuItems,
      CommonRulesFields,
      VirtualNewFields
    }
  },
  mixins: [ chatConfig ]
})
export default class AnyMessageView extends Mixins<UseFields, InputSetups, FilterViewMixin>(UseFields, InputSetups, FilterViewMixin) {

  options(options: string): Array<SelectOption> {
    return (chatConfig as any).computed[options]
  }
}
